
#maintoggle {
  display: none;
}

#nav {
  display: grid;
  grid-template-columns: 3.75em 1fr;
  grid-template-areas: "logo main" "logo secondary" "tertiary tertiary";

  .logo {
    grid-area: logo;

    margin-top: 6px;
    background-image: url("Blep.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
  }

  .mobile-logo {
    display: none;
  }

  .main {
    grid-area: main;
  }

  .secondary {
    grid-area: secondary;
  }

  .tertiary {
    grid-area: tertiary;
  }
}

header#top {
  font-size: 1.05em;
  margin: 0 $base-padding 0.8rem;
  color: themed("color-text");
  background-color: themed("color-background");
  background-image: themed("image-background");

  #subnav-height-placeholder {
    visibility: hidden;
  }

  menu {
    margin-top: -2px;
    background-color: themed("color-foreground");
    border-radius: $border-radius-full;
    padding: 6px;

    form {
      display: inline-block;

      input {
        width: 9.5em;
        vertical-align: baseline;
        font-size: revert;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    li {
      margin: 0;
      padding: 0;
      white-space: nowrap;
    }

    li a {
      padding: 6px 10px;
    }
  }

  menu.main {
    margin-top: 0;
    background-color: themed("color-background");

    li.current a {
      background-color: themed("color-foreground");
      font-weight: bold;
    }

    li#nav-sign-in a {
      font-weight: bold;
      color: $page-header-sign-in-link-color;
    }

    li.unread a::after, li.forum-updated a[data-form="bubble"]::after {
      content: unicode('f111');
      @include FontAwesomeFamily;
      font-weight: 900;
      font-size: 0.5em;
      color: red;

      position: absolute;
      @include window-larger-than(800px) {
        top: -3px;
      }
    }

    li.forum-updated a[data-form="italic"] {
      font-style: italic;
    }
  }

  &:not(.notification) {
    menu.tertiary {
      display: none;
    }
  }

  &.notification {
    menu.secondary {
      border-radius: 6px 6px 0 0;
    }

    menu.tertiary {
      border-radius: 6px 0 6px 6px;
      font-size: 1.5em;
      font-weight: bold;
    }
  }
}
