.user-feedback-positive {
  color: palette("text-green");
}

.user-feedback-neutral {
  color: palette("text-grey");
}

.user-feedback-negative {
  color: palette("text-red");
}

.user-feedback-deleted {
  color: palette("text-yellow");
}

div#c-users-feedbacks, div#c-moderator-dashboards .activity-container {
  #user-feedbacks-table {
    tr[data-category="positive"] {
      background: palette("background-green");
    }

    tr[data-category="neutral"] {
      background: palette("background-grey");
    }

    tr[data-category="negative"] {
      background: palette("background-red");
    }

    tr[data-is-deleted="true"] {
      background: palette("background-yellow");
    }
  }

  blockquote {
    padding: 0.5em;
  }

  .show-all-user-feedbacks-link {
    font-size: 1.5em;
    width: 100%;
    text-align: center;
    padding: 0.5em 0;
  }
}

#c-users-feedbacks,  #c-bans {
  .record-wrapper {
    display: flex;
    flex-flow: column;
    max-width: 900px;

    & > button {
      display: flex;
      width: max-content;
      padding: 0.25rem 1rem;
      order: 9000;
    }
  }

  .record-builder {
    background: #ffffff10;
    padding: 0.5rem;
    margin: 0.5rem 0;

    div {
      display: flex;
      flex-wrap: wrap;
      gap: 0.25rem;
      margin-bottom: 0.25rem;

      &.record-header {
        display: grid;
        grid-template-columns: auto max-content;

        .quick-record {
          justify-content: space-evenly;
        }

        & > button {
          margin-bottom: 0.25rem;
        }
      }
    }

    label {
      display: block;
      width: 100%;
    }

    select,
    input {
      width: 100%;
    }

    textarea {
      width: 100%;
      min-height: 4rem;
      &.custom-reason {
        min-height: 2rem;
      }
    }

    button {
      padding: 0.5rem 0.4rem;
      &.active {
        background: #e8c446;
      }
    }

    .rule-wrapper {
      display: flex;
      flex-flow: column;

      .rule-container label:only-child {
        display: none;
      }
    }
  }
}
