

div#news {
  max-height: 1rem;
  overflow-y: hidden;
  user-select: none;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &.open {
    max-height: none;
  }

  .newsbody {
    margin-top: 5px;
  }

  .closebutton {
    position: absolute;
    right: 0.5rem;

    cursor: pointer;
    font-size: 1.15rem;
    font-weight: bold;
    color: white;
  }
}
