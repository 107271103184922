div#search {
  margin-bottom: 1em;
}

div#page {
  overflow: visible;
  padding: $padding-050 $padding-025 themed("content-padding-bottom");

  @include window-larger-than(800px) {
    border-radius: $border-radius-full;
    margin: 0 1em;
    padding: $padding-050;
  }

  background-color: themed("color-foreground");
  background-image: themed("image-foreground");
  background-position: themed("image-foreground-position");
  background-repeat: themed("image-foreground-repeat");

  // Fix failure to contain contents in bounding box.
  &:after {
    display: block;
    visibility: hidden;
    content: '';
    clear: both;
  }

  div.site-notice {
    background-color: themed("color-section");
    padding: $padding-050;
    text-align: center;
    position: relative;
    border: 0;
    margin-bottom: 0.5rem;

    h1 {
      font-size: $h2-size;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  aside#sidebar {
    width: 15em;
    float: left;

    h1 {
      font-size: $h3-size;
    }

    #search-box {
      input[type=text] {
        /* ensure expanded search box renders above post thumbnails. */
        z-index: 1;
        position: relative;
        width: 13em;
        padding: 0.125rem 0.125rem;
        border-right: 0;
        box-sizing: border-box;
        border-radius: $border-radius-half 0 0 $border-radius-half;
      }

      button[type=submit] {
        padding: 2px 6px;
        background: $form-submit-button-background;
        width: 2em;
        border-left: 1px solid #ccc; // HACK: hardcoded color
        border-radius: 0 $border-radius-half $border-radius-half 0;
      }
    }
  }

  aside#sidebar > section {
    margin-bottom: 1em;
  }

  section#content {
    overflow: visible;
    margin-left: 15em;
    padding-left: 1em;
  }
}

.mobile-search {
  display: none;
}

footer#page-footer {
  display: grid;
  grid-template-columns: 1fr 5em 1fr;
  align-items: center;

  padding: $padding-050 $padding-025;
  margin-top: 1em;
  line-height: 1.75em;
  background-color: themed("color-foreground");

  @include window-larger-than(800px) {
    border-radius: $border-radius-full $border-radius-full 0 0;
    margin: 1em 1em 0 1em;
    padding: $padding-050;
  }

  .footer-logo {
    width: 5em;
  }

  .left {
    text-align: right;
    padding-right: 0.75em;
  }

  .right {
    text-align: left;
    padding-left: 0.75em;
  }
}

div.clearfix {
  clear: both;
}

.search-help {
  float: right;
  font-size: 70%;
}
