div#c-posts-replacements {
  .replacement-pending-row {
    background-color: themed("color-danger-darken-10");
  }

  .replacement-processing-row {
    background-color: themed("color-warning-darken-5");
  }

  .replacement-details-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    padding: 0.25em 0;
  }

  .replacement-reason {
    white-space: pre-wrap;
  }

  .disabled-link {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: themed("color-text-muted");
  }

  .upload_preview_img {
    max-width: 100%;
  }

  .upload_preview_container {
    margin-top: 0.5rem;
  }

  .error_message, .source_warning {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    width: fit-content;
  }

  #avoid-posting-notice ul {
    margin-left: 1em;
  }
}
