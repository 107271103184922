.mobile-only {
  display: none;
}

@media screen and (max-width: 900px) {
  #ad-leaderboard {
    height: 50px;
    justify-content: center;

    .adzone {
      height: 50px;
      width: 320px;
    }
  }
}

@media screen and (max-width: 800px) {
  .mobile-only {
    display: unset;
  }

  body.resp {
    #c-posts #a-show {
      .nav-block li {
        padding: 0 0.5em;

        .first span, .last span {
          display: none;
        }
      }
    }

    #search-box { display: none; }

    .mobile-search {
      display: block;
      margin: $padding-050 $padding-050 $padding-150;
      .search-help {
        font-size: $font-size;
      }
    }
    .mobile-search > .search-form > form {
      box-sizing: border-box;
      display: flex;
      width: 100%;
      margin-bottom: $padding-025;
      .search-input {
        width: 100%;
        input {
          width: 100%;
          box-sizing: border-box;
          border-radius: $border-radius-half 0 0 $border-radius-half;
        }
        flex-grow: 1;
      }
      .search-button > button {
        width: 32px;
        height: 32px;
        border-left: 1px solid #ccc; //HACK: hardcoded color
        border-radius: 0 $border-radius-half $border-radius-half 0;
      }
    }

    .guest-warning {
      .guest-warning-dialog {
        top: 5vh;
        height: 90vh;
        width: 80vw;
      }
    }

    div#page aside#sidebar {
      padding: 5px;

      input#tags {
        width: 80%;
      }

      button[type=submit] {
        font-size: 1.2em;
        background-color: #EEE;
        height: 42px;
        padding: 2px 10px;
        border: 1px solid rgb(197, 197, 197);
      }
    }

    div#page {

      > div /* div#c-$controller */
      {
        > div /* div#a-$action */
        {
          display: flex;
          flex-direction: column;

          /* Move #sidebar below #content. */
          > aside#sidebar {
            font-size: 1.5em;
            float: none;
            width: auto;
            order: 2;
          }
        }
      }
    }

    header {
      position: relative;
    }

    #page-footer {
      display: revert;
      text-align: center;

      .left {
        padding-right: revert;
      }

      .right {
        padding-left: revert;
      }

      .right::before {
        content: "–";
      }

      .logo-container {
        display: none;
      }
    }

    #maintoggle {
      display: block;
      font-weight: bold;
      font-size: 2em;
    }

    /* Make the quick search box in the navbar full width. */
    header#top menu form input {
      width: auto;
    }

    form {
      select, input, textarea, button {
        max-width: 100%;
      }

      select {
        vertical-align: top;
        font-size: 18pt;
      }

      input[type=text], input[type=email], input[type=password], input[type=number] {
        vertical-align: top;
        font-size: 18pt;
        border: 1px solid #c5c5c5;
        width: 90%;
      }

      textarea {
        width: 90%;
        vertical-align: top;
        border: 1px solid #c5c5c5;
      }

      button, input[type=button] {
        font-size: 160%;
      }

      input[type=submit] {
        font-size: 160%;
      }

      input[type=submit].tiny {
        font-size: 120%;
        vertical-align: top;
      }
    }

    form.simple_form {
      div.input {
        input[type=text], input[type=email], input[type=password], input[type=number] {
          width: 90%;
          max-width: 90%;
        }

        textarea {
          width: 90%;
          max-width: 90%;
        }
      }
    }

    div#page section#content {
      margin: 0;
      padding: 0;
    }

    #posts #posts-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;
      align-items: center;
      justify-content: space-evenly;
    }

    article.post-preview {
      float: none;
      margin: 0 0 10px 0;
      text-align: center;
      vertical-align: middle;
      display: inline-block;
      width: fit-content;

      a {
        margin: 0 auto;
      }

      img {
        max-height: 150px;
        max-width: 100%;
      }

      .desc {
        font-size: 100%;
        .post-score-views {
          display: none;
        }
      }
    }

    .user-disable-cropped-false {
      article.post-preview {
        img {
          height: auto;
          max-height: none;
          max-width: fit-content;
          width: 100%;

          &.has-cropped-false {
            object-fit: cover;
          }
        }
      }
    }

    div#options {
      margin-top: 10px;
      font-size: 24pt;
      font-weight: bold;
    }

    div#tags {
      margin-top: 30px;

      li {
        margin: 10px 0;
      }

      a.search-tag {
        font-size: 24pt;
        font-weight: bold;
      }
    }

    #nav {
      font-size: 2em;
      line-height: 2em;
      display: none;
    }

    header#top {
      input {
        font-size: 18pt;
      }

      menu.main {
        padding: 5px 10px;

        .mobile-logo {
          display: inline-block;
          width: 1.5em;
          box-sizing: border-box;

          a {
            display: inline-block;
            padding: 6px 10px;

            background-image: url("Blep.png");
            background-repeat: no-repeat;
            background-size: contain;

            // Gives the link height
            &::after {
              content: "Placeholder";
              visibility: hidden;
            }
          }
        }
      }

      menu.secondary.empty {
        display: none;
      }
    }

    header {
      text-align: center;
      line-height: 2em;

      h1 {
        display: inline; //Needed for menu button
      }
    }
  }
}

@media screen and (max-width: 800px) and (horizontal-viewport-segments: 2) {
  body.resp {
    #posts #posts-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media screen and (max-width: 660px) {
  body.resp {
    #c-forums-topics, #c-forums-posts {

      .creator {
        display: none;
      }
    }

    #c-forums {
      .topics, .posts {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 440px) {
  body.resp {
    body {
      width: 100%;
    }

    #post-sections {
      font-size: 1em;
    }
  }
}
