.guest-warning {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  background-color: themed("color-background");

  .guest-warning-dialog {
    z-index: 100;
    position: relative;
    top: 20vh;
    margin-left: auto;
    margin-right: auto;
    height: 40vh;
    width: 40vw;
    overflow-x: scroll;
    background-color: themed("color-section");

    .dialog-header {
      padding: $padding-050 $base-padding;
      background-color: themed("color-section-lighten-5");
      border-bottom: 1px solid themed("color-background");
    }
    .dialog-content {
      padding: $padding-050 $base-padding;
    }
    .dialog-footer {
      padding: $padding-050 $base-padding;
    }
  }
}
