#c-edit-histories {
  .edit-item {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1em;
  }

  .original {
    white-space: pre-wrap;
  }

  tr[data-edit-type="hide"] {
    background-color: palette("background-red");
  }

  tr[data-edit-type="unhide"] {
    background-color: palette("background-blue");
  }

  tr[data-edit-type="mark_warning"], tr[data-edit-type="mark_record"], tr[data-edit-type="mark_ban"] {
    background-color: palette("background-yellow");
  }

  tr[data-edit-type="unmark"] {
    background-color: palette("background-green");
  }
}
