#c-wiki-pages #a-show,
#c-wiki-pages-versions {
  .wiki-page-redirect {
    margin-left: 1em;
    color: var(--color-text-muted);
    & > i {
      display: inline-flex;
      margin-right: 0.25em;
    }
  }

  .wiki-page-redirect-history, .wiki-page-protection, .wiki-page-protection-history, .wiki-page-merge-from-history {
    color: var(--color-text-muted);
    margin-bottom: 1em;
  }

  #wiki-page-body {
    margin-top: 0.25em;
  }
}
