div#zoom-container {
  position: fixed;
  top: 100vh;
  z-index: 10000;
  pointer-events: none;

  & > img {
    max-width: calc(50vw - 50px);
    max-height: 80vh;
    background: #00000090 center center;
    background-size: contain;

    /*
    &::before {
        content: var(--image-info, "??? x ???, ???MB");
    }
    */
  }

  & > video#zoom-video {
    max-width: calc(50vw - 50px);
    max-height: 80vh;
    background: #00000090 center center;
    background-size: contain;
  }

  & > div {
    max-width: fit-content;
    max-height: 4rem;
    overflow: hidden;
    padding: 0 4px;

    background: #000000aa;
    font-family: monospace;
    line-height: 1rem;

    box-sizing: border-box;
  }

  & > div#zoom-info {
    color: white;
    padding-right: 0.35rem;

    & > span:not(:last-child)::after {
      content: " | ";
      color: white;
    }
  }

  & > div#zoom-tags {
    color: white;
  }

  &[status=waiting] { display: none; }
  &[status=loading] {
    &::after {
      content: unicode("f1ce");
      @include FontAwesomeFamily;
      font-weight: 900;
      font-size: 3rem;
      line-height: 3rem;
      text-shadow: 0 0 3px black;

      animation: fa-spin 2s infinite linear;

      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
