#c-users-mfa, #c-users-mfa-backup-codes, #c-sessions #a-create #p-confirm-mfa {
  #mfa-qr-code {
    width: 200px;
    padding: .5rem;
    margin-bottom: .5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
    border-radius: .5rem;
    border-width: 1px;
    background-color: #333333;
  }

  .mfa-info {
    background-color: #222222;
    align-items: center;
    flex-direction: column;
    max-width: 540px;
    padding: 1rem;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
    border-radius: .5rem;
    border-width: 1px;
    display: flex;
  }

  #new_mfa {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  #new_mfa div.input {
    width: 100%;
    margin-bottom: 1em;
  }

  #new_mfa div.input input[type] {
    max-width: unset;
    width: 100%;
  }

  #new_mfa div.input label {
    display: block;
    line-height: 1.5em;
  }

  .mfa-title {
    padding-bottom: 1rem;
  }

  .full {
    width: 100%;
  }

  .show-code-link {
    font-size: 75%;
  }

  .mfa-secret {
    display: block;
    text-align: center;
  }

  .divider {
    width: 100%;
    margin-bottom: 1rem;
  }

  .mfa-backup-code {
    font: 1.2rem monospace;
    text-align: center;
    display: block;
  }

  .mfa-backup-buttons {
    justify-content: center;
    gap: 1rem;
    display: flex;
  }
}
