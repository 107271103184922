#c-tickets {
  #a-new {
    .section {
      max-width: 80em;
    }

    form.simple_form {
      background-color: transparent;
    }

    .ticket-warning {
      display: block;
      background: var(--color-section-lighten-5);
      border-radius: 6px;
      padding: 0.5em;
      margin-bottom: 1em;

      ul {
        list-style: disc;
        margin: 0.5em 1.2em;
      }
    }
  }

  #tickets-table {
    tr[data-status="approved"] .status-column {
      color: palette("text-green");
      font-weight: bold;
    }

    tr[data-status="rejected"] .status-column {
      color: palette("text-red");
      font-weight: bold;
    }

    tr[data-status="partial"] .status-column {
      color: palette("text-yellow");
      font-weight: bold;
    }
  }
}
