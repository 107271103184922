$levels: (
  "banned"
  "restricted"
  "member"
  "trusted"
  "former-staff"
  "janitor"
  "moderator"
  "system"
  "admin"
  "owner"
);

@each $level in $levels {
  .user-#{$level}.with-style {
    color: themed("color-user-#{$level}");

    &:hover {
      color: themed("color-user-#{$level}-alt");
    }
  }
}

.with-style {
  &.user-banned {
    text-decoration: line-through;
  }

  &.user-trusted, &.user-former-staff, &.user-janitor, &.user-moderator, &.user-system, &.user-admin, &.user-owner {
    font-weight: bold;
  }
}

.ip-addr.is-banned {
  text-decoration: line-through;
}
