.user-greeting-outer {
  padding: $padding-025 0 $padding-025;
  span.user-greeting {
    color: $user-home-greeting-color;
    cursor: help;
  }
}

div#c-users {
  div#a-show {
    .profile-container {
      width: 100%;
    }

    .bottom-section {
      display: flex;
      flex-flow: column;
    }

    .stats-section {
      display: flex;
    }

    .posts-section {
      margin-bottom: 1em;

      .blacklist {
        margin-bottom: 0.5em;
      }

      .posts {
        display: flex;
        flex-flow: column;
        gap: 0.5rem;
      }

      .box {
        display: grid;
        grid-template-areas: "box-label box-extra"
            "box-posts box-posts";
        grid-template-columns: auto 1fr;
        gap: 0 0.25rem;
      }

      .box h2.box-label {
        display: block;
        grid-area: box-label;
        font-size: 1.5em; // Mobile shenanigans
        margin: unset;

        a {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          width: min-content;
          height: 100%;
          box-sizing: border-box;
          padding: 0.125em;

          background: var(--color-section);
          border-radius: 6px 6px 0 0;
          min-width: 150px;

          &:focus, &:active {
            outline: 0;
            color: var(--color-link-active);
          }
        }
      }

      .box span.box-extra {
        grid-area: box-extra;
        display: flex;
        flex-wrap: wrap;
        gap: 0.25rem;
        margin-bottom: 0.25em;

        a {
          display: flex;
          background: var(--color-section);
          align-items: center;
          padding: 0.125rem 0.5rem;
          border-radius: 6px;
          white-space: nowrap;

          &:focus, &:active {
            outline: 0;
            color: var(--color-link-active);
          }
        }

        span.text {
          display: flex;
          align-items: center;
          justify-items: center;
          padding: 0.125rem 0.5rem;
          white-space: nowrap;
        }
      }

      .box div.box-posts {
        grid-area: box-posts;

        display: grid;
        grid-template-columns: repeat(auto-fit, 150px);
        grid-auto-flow: column;

        min-height: 201px; // Yes, 201. 150 + 3 * 1em
        border-radius: 0 6px 6px 6px;
        background: var(--color-section);
        align-items: self-end;
        justify-content: space-between;
        overflow: hidden;
        padding: 1em;
        gap: 1em;
        box-sizing: border-box;

        article.post-preview {
          margin: 0;
          width: 150px;
          overflow: visible;

          img {
            width: 100%;
          }
        }
      }
    }

    .profile-avatar {
      margin-right: 2em;
    }

    .about-section {
      display: flex;
      flex-grow: 2;
      gap: 1em;

      .about-piece {
        flex-grow: 1;
      }
      div {
        flex-basis: 50%;
      }
    }

    .profile-stats {
      width: 100%;
      border-radius: $border-radius-full;
      padding-left: 0.5em;
      background: themed("color-section");

      abbr {
        cursor: help;
      }
    }

    .user-statistics {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .column {
        display: grid;
        grid-template-columns: max-content minmax(150px, 1fr);
        grid-auto-rows: minmax(1.75em, max-content);
        column-gap: 1em;

        > span:nth-child(odd) {
          font-weight: bold;
          text-align: right;
        }
      }
    }
  }

  div#a-edit {
    h1 {
      margin: 0.5em 0;
    }

    h2 {
      margin: 0.5em 0;
    }

    div.input {
      margin-bottom: 2em;
    }

    div.input span.hint {
      display: block;
      max-width: 70%;
    }

    .active {
      color: themed("color-link-active");
    }
  }

  div#a-new {
    max-width: 60em;

    p {
      font-size: 1.2em;
      line-height: 1.4em;
    }
  }
}

@media screen and (max-width: 800px) {
  body.resp {
    div#c-users {
      div#a-show {
        .stats-section {
          flex-direction: column;
        }
        .bottom-section {
          flex-direction: column;
        }
        .vertical-section {
          flex-direction: row;
          flex-wrap: wrap;
        }
        .posts-section {
          order: 2;
          padding: 0 5px;
        }
        .about-section {
          flex-direction: column;
        }
        .about-piece {
          margin: 0 0 $padding-050;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
body.resp #c-users #a-show {
  .posts-section {
    .box {
      grid-template-areas: "box-label"
      "box-extra"
      "box-posts";
      grid-template-columns: auto;
    }
    .box h2.box-label a {
      border-radius: 6px;
    }
    .box span.box-extra {
      margin-top: 0.25em;
    }
    .box div.box-posts {
      grid-auto-flow: dense;
      border-radius: 6px;
      padding: 1em 0.25em;
      gap: 1em 0.25em;
    }
  }
}
}
